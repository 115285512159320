import React from "react";
import { graphql } from "gatsby";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import QuickInfoCard from "./QuickInfoCard"

const QuickInfo = ({ block }) => {
  const { quickInfoTitle, cards } = block
  return (
    <Section>
      <StructuredTextRenderer data={quickInfoTitle} isParagraphClassName="nobottom"/>
      <div className="row">
        {cards.map((card) => <QuickInfoCard key={card.id} card={card} />)}
      </div>
    </Section>
  )
}

export default QuickInfo

export const query = graphql`
  fragment QuickInfo on DatoCmsQuickinfoblock {
    model {
      apiKey
    }
    id: originalId
    quickInfoTitle {
      value
    }
    cards {
      ...InfoCard
    }
  }
`;