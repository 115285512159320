import React from "react";
import { graphql } from "gatsby";
import cn from 'classnames'
import { Icon } from "@soundtrackyourbrand/ui/src/Icon";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import * as styles from "./QuickInfo.module.scss";

const QuickInfoCard = ({ card }) => {
  const { title, shortText } = card
  const icon = card.icon ? card.icon[0] : null;
  return (
    <div className={cn('col sm-6 l-4', styles.card)}>
      <div className={cn('mb-6', styles.iconWrapper)}>
        <Icon
          size="medium"
          name={icon.iconType}
          className={cn(styles.icon)}
        />
      </div>
      <p>{title}</p>
      <div>
        <StructuredTextRenderer data={shortText} isParagraphClassName="subtle nobottom" />
      </div>
    </div>
  )
}

export default QuickInfoCard

export const query = graphql`
  fragment InfoCard on DatoCmsInfocard {
    id: originalId
    title
    shortText {
      value
    }
    icon {
      iconType
      iconColor {
        hex
      }
    }
  }
`;